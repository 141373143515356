import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import BottonMenu from "./Bottom";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer mb_space">
      <div className='pt-5 mt-5   Orher_page_main_section'>
        <div className="main-area">
          <div className='gameCard pt-2'>
          {WebSitesettings.homeMsg &&
            <div className='alrt_msg_div'>
              <h5 className=''>
                {WebSitesettings.homeMsg}
              </h5>
            </div>
          }
          </div>
          {/*
<div className="w1">
<a href="https://t.me/kingaddasupport">
<button className="w2">
<span className="w3">किसी भी समस्या के लिए Telegram पे कॉन्टेक्ट करे।</span>
</button>
</a>
</div>
*/}
                       
                    

          <section className="games-section p-3">
            <div className="d-flex align-items-center games-section-title">
              Our Tournaments
            </div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics`}
                >
                  <span className="d-none blink text-success d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/LudoClassic.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LUDO CLASSICS
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div> */}
                </Link>

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ Comming Soon
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/LudoTeam.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                 <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ TEAM LUDO
                    </span>
                  </div>
                  */}
                 <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>
               
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ COOMING SOON
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/LudoSnake.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                  <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ Ludo Classic 1G
                    </span>
                  </div>
                  */}
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ COMMING SOON
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/FirstSixWin.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                  <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ Ludo Classic 2G
                    </span>
                  </div>
                  */}
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>



              </div>
            </div>
          </section>
          
          <div className="downloadButton">
            <Downloadbutton />
          </div>

        </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
