import React from 'react'
import { NavLink } from 'react-router-dom'
import { SlHome } from 'react-icons/sl'
import { BsWallet, BsGift } from 'react-icons/bs'
import { FiUser, FiUsers } from 'react-icons/fi'
import { AiOutlineMessage } from 'react-icons/ai'
import { BiMessageSquareDetail } from 'react-icons/bi'

const BottonMenu = () => {
  return (
    <div className='main_bottom_menus' style={{}}>
      <nav className='bottom_link_main'>
        <NavLink
          exact
          to='/landing'
          className='bottom_link '
          activeClassName='active_link'
        >
          <SlHome className='sidebar_link_icon' />
          <span> Home</span>
        </NavLink>
        <NavLink
          exact
          to='/wallet'
          className='bottom_link '
          activeClassName='active_link'
        >
          <BsWallet className='sidebar_link_icon' />

          <span> My wallet</span>
        </NavLink>
        <NavLink
          exact
          to='/refer'
          className='bottom_link '
          activeClassName='active_link'
        >
          <FiUsers className='sidebar_link_icon' />

          <span> Refer</span>
        </NavLink>
        <NavLink
          exact
          to='/support'
          className='bottom_link '
          activeClassName='active_link'
        >
          <BiMessageSquareDetail className='sidebar_link_icon' />

          <span> Support</span>
        </NavLink>
        <NavLink
          exact
          to='/Profile'
          className='bottom_link '
          activeClassName='active_link'
        >
          <FiUser className='sidebar_link_icon' />

          <span> Profile</span>
        </NavLink>
      </nav>
    </div>
  )
}

export default BottonMenu
